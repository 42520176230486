
import { computed, defineComponent, onBeforeMount, ref } from 'vue';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { object } from 'vue-types';

import { routes } from '@/router/index';

export default defineComponent({
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
  },
  setup() {
    const route = useRoute();

    const selectedKeys = ref<string[]>([String(route?.name)]);
    const openKeys = ref<string[]>([]);
    const tabMenu = routes;

    const getTabMenu = computed(() => {
      return tabMenu.filter((obj) => !!obj.label);
    });

    onBeforeMount(() => {
      const ownTab = tabMenu.find((obj) =>
        obj.children?.some((obj) => obj.name === route.name)
      );
      openKeys.value = [ownTab?.label || ''];
    });

    return {
      // state
      selectedKeys,
      openKeys,
      collapsed: ref<boolean>(false),
      tabMenu,
      // func
      // computed
      getTabMenu,
    };
  },
});
